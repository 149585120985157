import React, { Component } from 'react';
import Layout from "../components/layout";
import Intro from "../components/intro";
import BlogItem from "../components/blogItem";
import BlogCategory from "../components/blog-category";
import Nav from '../components/nav';
import moment from 'moment';

class IndexPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            items: [],
            isBlogLoaded: false,
            blogContent: [],
            isAwardLoaded: false,
            awardContent: [],
            isCategoryLoaded: false,
            categoryContent: [],
            author_name: null,
            category_count: null,
            year_count: null
        }
    }

    async componentDidMount() {
        const id = this.props
        console.log("componentDidMount", JSON.stringify(id))
        console.log("componentDidMount params", JSON.stringify(id.params))
        let category_name = id.params["*"];
        this.setState({ author_name: category_name })
        this.getAllCategoryByType();
        this.getAllBlog(category_name);
        this.getCMSCaterogy(category_name)
    }

    componentWillReceiveProps(nextProps) {
        console.log("App componentWillReceiveProps nextProps", nextProps.params["*"])
        let category_name = nextProps.params["*"];
        this.setState({ author_name: category_name })
        this.getAllCategoryByType();
        this.getAllBlog(category_name);
        this.getCMSCaterogy(category_name)
    }

    getAllBlog(category_name) {
        fetch("https://tatva.com/backendapi/api/v1/cms/by-category-name/" + category_name)
            .then(res => res.json())
            .then(
                (result) => {

                    const filteredDates = result.data.sort((a, b) => new Date(b.publish_date) - new Date(a.publish_date))
                    console.log("filteredDates", filteredDates)

                    // this.setState({
                    //     isBlogLoaded: true,
                    //     blogContent: filteredDates
                    // });

                    let result2 = result.data.reduce(function (r, a) {
                        r[a.category] = r[a.category] || [];
                        r[a.category].push(a);
                        return r;
                    }, Object.create(null));
                    // console.log("result2", result2);


                    let result2publish_date = result.data.reduce(function (r, a) {
                        // let year = moment(a.publish_date,"YYYY-MM-DD").year()
                        // console.log("year", year + a.publish_date)
                        r[moment(a.publish_date, "YYYY-MM-DD").year()] = r[moment(a.publish_date, "YYYY-MM-DD").year()] || [];
                        // console.log(r[moment(a.publish_date, "YYYY-MM-DD").year()] = r[moment(a.publish_date, "YYYY-MM-DD").year()] || [])
                        r[moment(a.publish_date, "YYYY-MM-DD").year()].push(a);
                        // console.log(a)
                        return r;
                    }, Object.create(null));
                    // console.log("result2publish_date", result2publish_date);



                    this.setState({
                        isBlogLoaded: true,
                        blogContent: filteredDates,
                        category_count: result2,
                        year_count: result2publish_date
                    });
                },
                (error) => {
                    this.setState({
                        isBlogLoaded: true,
                        error
                    });
                }
            )
    }

    getAllCategoryByType() {
        fetch("https://tatva.com/backendapi/api/v1/cms-category/by-cms-type/blog")
            .then(res => res.json())
            .then(
                (result) => {

                    this.setState({
                        isCategoryLoaded: true,
                        categoryContent: result
                    });
                },
                (error) => {
                    this.setState({
                        isCategoryLoaded: true,
                        error
                    });
                }
            )
    }

    async getCMSCaterogy(category_name) {
        fetch("https://tatva.com/backendapi/api/v1/cms-category/by-category-name/" + category_name)
            .then(res => res.json())
            .then(
                (result) => {
                    const filteredDates = result.data
                    console.log("by-category-name", filteredDates)
                    this.setState({
                        isLoaded: true,
                        catItems: filteredDates
                    });
                },
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
    }



    render() {

        const { isBlogLoaded, blogContent, isCategoryLoaded, categoryContent, author_name, catItems,
            category_count,
            year_count
        } = this.state;



        return (
            <Layout>

                {isBlogLoaded ?
                    <Nav
                        active="blog"
                        title={catItems?.document_title}
                        description={catItems?.meta_description}
                        keywords={catItems?.meta_tag}
                    ></Nav>
                    : ''}

                <Intro title="Our Blog" search="true"
                    searchType="blog" description="Our blog provides legal and commercial information, analysis and updates of national and regional relevance."> </Intro>

                <div className="main-container" id="blog-category-page">
                    <div className="container">

                        {isBlogLoaded ?
                            <div className="blog-category-top">
                                <span className="blog-category-top-link" title={author_name}>
                                    <span dangerouslySetInnerHTML={{ __html: author_name }}></span> </span>
                            </div>
                            : ''}

                        <div className="row">
                            <div className="col-md-9 col-sm-12">
                                <div className="blog-container">
                                    {isBlogLoaded ?
                                        <React.Fragment>
                                            {blogContent.map(item => (
                                                <div className="" key={item._id}>
                                                    <BlogItem
                                                        category="CORPORATE LAW"
                                                        title={item.title}
                                                        author={item.created_by}
                                                        date={item.publish_date}
                                                        short_content={item.short_description}
                                                        slug={item.slug}
                                                        description={item.description}
                                                    >
                                                    </BlogItem>
                                                </div>
                                            ))}
                                        </React.Fragment>
                                        : ''}
                                </div>
                            </div>
                            {isCategoryLoaded & isBlogLoaded ?
                                <BlogCategory
                                    category_count={category_count}
                                    year_count={year_count}
                                    className={"col-md-3 col-sm-12"} categoryContent={categoryContent}></BlogCategory>
                                : ''}
                        </div>
                    </div>
                </div>
            </Layout>
        )
    }
}

export default IndexPage;